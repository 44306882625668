@font-face {
  font-family: "Roboto";
  src: url("Roboto-Light.woff") format("woff"),
    url("Roboto-Light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Regular.woff") format("woff"),
    url("Roboto-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Medium.woff") format("woff"),
    url("Roboto-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Bold.woff") format("woff"),
    url("Roboto-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Bold.woff") format("woff"),
    url("Roboto-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("Roboto-Black.woff") format("woff"),
    url("Roboto-Black.woff2") format("woff2");
  font-weight: 900;
  font-style: normal;
}
